<template>
  <div>
    <Navbar />
    <section class="bg-half-170 pb-0 d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="title-heading text-center mt-4">
              <img
                src="images/icon/credit-card.svg"
                class="avatar avatar-md-sm"
                alt=""
              />
              <h6 class="text-primary mt-3 font-weight-bold">PRICING</h6>
              <h1 class="heading mb-3">TREEP's flexible, simple pricing plans</h1>
              <p class="para-desc mx-auto text-muted">Best value plans that grow with you. Try our 14-day free trial.</p>
              <div class="mt-4 pt-2">
                <a href="https://new.treep.cc/#/light-openTrial" target="_blank" class="btn btn-primary">Start free trial<i class="mdi mdi-chevron-right"></i></a>
              </div>
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="container mt-100 mt-60">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">
                        Choose a plan, invest in your business
                    </h4>
                    <p class="para-desc mx-auto text-muted mb-0">
                        <!-- Start working with
                        <span class="text-primary font-weight-bold">Landrick</span> that
                        can provide everything you need to generate awareness, drive
                        traffic, connect. -->
                        We partner with over 1,000 users to help them sell more, save time, and deliver better experiences for their guests.
                    </p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card pricing-rates business-rate border-0 p-4 rounded-md shadow">
                        <div class="card-body p-0">
                            <span class="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary rounded-lg">Light</span>

                            <h2 class="font-weight-bold mb-0 mt-3">$49.00</h2>
                            <!-- <p class="text-muted">Per Month</p> -->

                            <p class="text-muted">For businesses looking to get bookings online</p>
                            <ul class="list-unstyled pt-3 border-top">
                                <li class="h6 text-muted mb-0">
                                    <span class="text-primary h5 mr-2">
                                        <i class="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Sell online via booking widgets
                                </li>
                                <li class="h6 text-muted mb-0">
                                    <span class="text-primary h5 mr-2">
                                        <i class="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Manually input bookings
                                </li>
                                <li class="h6 text-muted mb-0">
                                    <span class="text-primary h5 mr-2">
                                        <i class="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Price and inventory management base on calendar and channels
                                </li>
                                <li class="h6 text-muted mb-0">
                                    <span class="text-primary h5 mr-2">
                                        <i class="uil uil-check-circle align-middle"></i>
                                    </span>
                                    No fees on offline bookings
                                </li>
                            </ul>

                            <div class="mt-4">
                                <a href="https://new.treep.cc/#/light-openTrial" target="_blank" class="btn btn-block btn-primary">Start free trial</a>
                                <p class="text-muted mt-3 mb-0">No credit card required</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card pricing-rates business-rate border-0 p-4 rounded-md shadow">
                    <!-- <div class="ribbon ribbon-right ribbon-warning overflow-hidden">
                        <span class="text-center d-block shadow small h6">Advanced</span>
                    </div> -->
                    <div class="card-body p-0">
                        <span class="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary rounded-lg">Advanced</span>

                        <h2 class="font-weight-bold mb-0 mt-3">$399.00</h2>
                        <!-- <p class="text-muted">Per Month</p> -->

                        <p class="text-muted">For growing businesses looking to increase, and manage selling, on multiple channels</p>

                        <ul class="list-unstyled pt-3 border-top">
                            <li class="h6 text-muted mb-0">
                                <span class="text-primary h5 mr-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>
                                Everything included in Light
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="text-primary h5 mr-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>
                                OTA Platform API sync
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="text-primary h5 mr-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>
                                Agent portal for booking agents
                            </li>
                            <li class="h6 text-muted mb-0">
                                <span class="text-primary h5 mr-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>
                                Promotion tools to boost more sales
                            </li>
                        </ul>

                        <!-- <div class="mt-4">
                            <a href="javascript:void(0)" class="btn btn-block btn-primary">Buy Now</a>
                            <p class="text-muted mt-3 mb-0">*No credit card required</p>
                        </div> -->
                    </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-4 col-md-6 mt-4 pt-2">
                    <div class="card pricing-rates business-rate border-0 p-4 rounded-md shadow">
                        <div class="card-body p-0">
                            <span class="py-2 px-4 d-inline-block bg-soft-primary h6 mb-0 text-primary rounded-lg">Enterprise</span
    >
                            <h2 class="font-weight-bold mb-0 mt-3">$599.00</h2>
                            <!-- <p class="text-muted">Per Month</p> -->

                            <p class="text-muted">For sophisticated, high volume businesses looking to manage at scale</p>

                            <ul class="list-unstyled pt-3 border-top">
                                <li class="h6 text-muted mb-0">
                                    <span class="text-primary h5 mr-2">
                                        <i class="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Everything included in Advanced
                                </li>
                                <li class="h6 text-muted mb-0">
                                    <span class="text-primary h5 mr-2">
                                        <i class="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Unlimited extra sub-account
                                </li>
                                <li class="h6 text-muted mb-0">
                                    <span class="text-primary h5 mr-2">
                                        <i class="uil uil-check-circle align-middle"></i>
                                    </span>
                                    Mobile Apps for OP
                                </li>
                            </ul>

                            <!-- <div class="mt-4">
                                <a href="javascript:void(0)" class="btn btn-block btn-primary">Buy Now</a>
                                <p class="text-muted mt-3 mb-0">*No credit card required</p>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="container pb-lg-4 mb-md-5 mb-4 mt-100 mt-60">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="section-title">
                        <h4 class="title mb-4">Don't Know which plan to choose?</h4>

                        <p class="text-muted para-desc mx-auto mb-0">
                            <!-- Start working with
                            <span class="text-primary font-weight-bold">Landrick</span> that
                            can provide everything you need to generate awareness, drive
                            traffic, connect. -->
                            Book a meeting with our travel digitalization expert. Or Start a free trial, upgrade, downgrade or cancel your plan at any time for maximum freedom.
                        </p>

                        <div class="mt-4">
                            <a href="https://treep.cc/demo/?channelType=1" target="_blank" class="btn btn-primary mt-2 mr-2">Book a meeting</a>
                            <a href="https://new.treep.cc/#/light-openTrial" target="_blank" class="btn btn-outline-primary mt-2">Start free trial</a>
                        </div>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
        </div>
      </div>
      <!--end container-->
    </section>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      
    };
  },
  mounted: () => {
    
  },
  methods: {
    
  },
}
</script>

<style>

</style>